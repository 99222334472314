<template>
  <div class="main-container" >
    <div  class="main" style="margin: 50px auto" >
      <h1>Регистрация </h1>
      <br />
      <div><b>1. Роль</b></div>
      <select v-model="selectedRole" class="field"
              :class="{'invalid': !roleValid}">
        <option disabled :value="null" selected >Выберете роль</option>
        <option v-for="role in roles" :key="role.value" :value="role.value">{{role.title}}</option>
      </select>
      <br />
      <div><b>2. Организация</b></div>
      <select v-model="selectedOrganization" class="field" required
              :class="{'invalid': !organizationValid}">
        <option :value="null" selected disabled v-if="!selectedRole">Сначала выберете роль</option>
        <option :value="null" selected disabled v-if="selectedRole">Выберете вашу организацию</option>
        <option v-for="org in limitedOrganizations" :key="org.value" :value="org.value" :disabled="org.disabled" :selected="org.selected">{{org.title}}</option>
      </select>
      <br />
      <div><b>3. Фамилия</b></div>
      <input
              class="inputText field"
              type="text"
              name="surname"
              id="surname"
              v-model="lastName"
              placeholder="Фамилия"
              :class="{'invalid': !lastNameValid}"
      />
      <br />
      <div><b>4. Имя</b></div>
      <input
              class="inputText field"
              type="text"
              name="name"
              id="name"
              v-model="firstName"
              placeholder="Имя"
              :class="{'invalid': !firstNameValid}"
      />
      <br />
      <div><b>5. Отчество</b></div>
      <input
              class="inputText field"
              type="text"
              name="patronymic"
              id="patronymic"
              v-model="patronymic"
              placeholder="Отчество"

      />
      <!--:class="{'invalid': !patronymicValid}"-->
      <br />
      <div><b>6. Телефон</b></div>
      <input
              class="inputText field"
              type="text"
              name="phone"
              id="phone"
              v-model="phone"
              placeholder="Телефон"
              :class="{'invalid': !phoneValid}"
      />
      <br />
      <div><b>7. Логин</b></div>
      <input
              class="inputText field"
              type="text"
              name="email"
              id="email"
              v-model="email"
              placeholder="Электронная почта"
              :class="{'invalid': !emailValid}"
      />
      <br />
      <div class=""><b>8. Пароль</b></div>
      <input
              class="inputText field password"
              :type="!passwordVisible ? 'password' : 'text'"
              autocomplete="password"
              name="password"
              id="password"
              v-model="password"
              placeholder="Пароль (мин. 4 знака)"
              :class="{'invalid': !passwordValid}"
      />
      <!--<span class="glyphicon glyphicon-eye-open "></span>-->
      <span v-show="password" class="glyphicon eye" @click="passwordVisible = !passwordVisible">&#128065;</span>
      <span v-show="password && passwordVisible" class="glyphicon eye-cross" >&#47;</span>
      <br />
      <input
              style="height: 40px"
              type="button"
              value="Отправить"
              class="button field"
              id="done"
              @click="register"
      />
      <br />

      <div ><a href="/signin"><h4 style="color:black; text-decoration: underline">вернуться на страницу входа</h4></a></div>
    </div>
  </div>
</template>

<script>

  const testName = /^[a-zA-Zа-яА-Я]+[0-9a-zA-Zа-яА-Я\S\d]+$/i
  const emailTest =  /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/i
  const phoneTest = /^[+]{0,1}[0-9a-zA-Zа-яА-Я]+[a-zA-Zа-яА-Я\S\d]+$/i


  import {signup} from "../api/auth-controller";
  import {mapActions} from 'vuex'

  export default {
    name: "Registration",
    //Custom style for main and input for make the page responsive:
    props: {
      mainStyle: String,
    },
    computed: {
      limitedOrganizations: function () {
        let result = this.organizations.filter(org => org.roles.includes(this.selectedRole))
        // console.log('limited orgs', result, 'selected role', this.selectedRole)
        return result
      },
      organizationValid() {
        return !this.validationStarted || this.selectedOrganization && this.selectedOrganization === 'feerc'
      },
      roleValid() {
        return !this.validationStarted || this.selectedRole && this.roles.some(r => r.value === this.selectedRole)
      },
      lastNameValid() {
        return !this.validationStarted || this.lastName && testName.test(this.lastName)
      },
      firstNameValid() {
        return !this.validationStarted || this.firstName && testName.test(this.firstName)
      },
      patronymicValid() {
        return !this.validationStarted || this.patronymic && testName.test(this.patronymic)
      },
      emailValid() {
        return !this.validationStarted || this.email && emailTest.test(this.email)
      },
      phoneValid() {
        return !this.validationStarted || this.phone && phoneTest.test(this.phone)
      },
      passwordValid() {
        return !this.validationStarted || this.password && this.password.length > 3
      }
    },
    data() {
      return {
        passwordVisible: false,
        validationStarted: false,
        roles:  [
          // {value: 0, title: 'Выберете роль'},
          {order: 1, title: 'Администратор системы', value: 'administrator'},
          {order: 2, title: 'Океанолог', value: 'oceanographer'},
          {order: 3, title: 'Сейсмолог', value: 'seismologist'},
          {order: 4, title: 'Эксперт', value: 'expert'}
        ],
        selectedRole: null,
        organizations: [
          {value: 'ФИАЦ', roles: ['administrator', 'oceanographer', 'seismologist', 'expert'], title:'ФИАЦ', selected: true },
          {value: 2, roles: ['administrator', 'oceanographer', 'seismologist'], title:'Сахалинское УГМС', disabled: true},
          {value: 3, roles: ['administrator', 'oceanographer', 'seismologist'], title:'Приморское УГМС', disabled: true},
          {value: 4, roles: ['administrator', 'oceanographer', 'seismologist'], title:'Камчатское УГМС', disabled: true}
        ],
        selectedOrganization: null,
        firstName: '',
        lastName: '',
        patronymic: '',
        phone: '',
        email: '',
        password: ''
      }
    },
    methods: {
      ...mapActions(['showInfoAlert']),
      validate() {
        this.validationStarted = true
        return this.organizationValid && this.roleValid && this.lastNameValid && this.firstNameValid && this.emailValid && this.phoneValid && this.passwordValid
      },
      async register() {
        if (!this.validate()) {
          this.showInfoAlert('Пожалуйста, заполните все поля')
          return;
        }
        return signup(this.email, this.lastName, this.firstName, this.patronymic, this.phone, this.selectedOrganization, this.selectedRole, this.password )
          .then(result => {
            // console.log('signup result', JSON.stringify(result))
            if (result.code === 'SUCCESS') {
              this.$router.push('/signup-done')
            } else {

              console.log('signup err', result)
              this.showInfoAlert(`Ошибка регистрации ${result.data.message}`)
            }
          })
          .catch(err => {
            console.log('signup err', err)
          })
      }
    }
  };
</script>

<style scoped>

  @media screen and (min-width: 800px) {
    .main-container {
      display: block;
      margin: 0 auto;
      height: 100%;
      width: 100%;
      max-width: 95vw;

      padding: 20px;
      /*width: 700px;*/
    }

    .main {
      padding: 20px 20px 0 0 ;
    }
  }

  .main-container {
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: rgba(0,0,0,0);
  }



  .main {
    background: rgba(255, 255, 255, 0.4);
    text-align: center;
    border-radius: 3rem;
    box-shadow: 0 0 8px -5px #000000;
    max-width: 750px;
    padding: 20px 5px 20px 5px;
  }

  h1 {
    cursor: default;
    user-select: none;
  }



  option {
    text-align: center;
  }

  select {
    max-width: 90vw;
  }

  input.inputText, input.button {
    border: none;
    text-align: center;
    outline: none;
    box-sizing: border-box;
    max-width: 90vw;
  }

  input:hover {
    box-shadow: 0px 0px 8px -5px #000000;
  }

  input:active {
    box-shadow: 0px 0px 8px -5px #000000;
  }

  #done {
    background: #98D0E8;
    min-width: 400px;
    width: 30%;
  }

  .button {
    cursor: pointer;
    user-select: none;
  }

  img {
    height: 2.2rem;
    margin: 10px;
    user-select: none;
  }

  img:hover {
    box-shadow: 0px 0px 8px -5px #000000;
    cursor: pointer;
    border-radius: 200rem;
  }

  #app .dropdown li {
    border-bottom: 1px solid rgba(112, 128, 144, 0.1)
  }

  #app .dropdown li:last-child {
    border-bottom: none;
  }

  #app .dropdown li a {
    padding: 10px 20px;
    display: flex;
    width: 100%;
    align-items: center;
    font-size: 1.25em;
  }

  #app .dropdown li a .fa {
    padding-right: 0.5em;
  }

  .invalid {
    border: 1px solid red !important;
  }



</style>
